import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';

import Breadcrumb from '~components/breadcrumbs';
import Hero from '~components/hero';

const TermsAndConditions = () => {
  const intl = useIntl();

  useLayout({ displayTopCTABar: false, displaySignIn: true });
  useSeo(
    intl.formatMessage({
      id: 'containers.terms-and-conditions.seo.title',
    })
  );
  const listingRedirect = useListingRedirect();

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <FormattedMessage id="routes.terms-and-conditions">
                {(msg) => (
                  <Breadcrumb.CrumbLink
                    to={`/${msg}`} index={2}>
                    {intl.formatMessage({
                      id: 'breadcrumbs.terms-and-conditions',
                    })}
                  </Breadcrumb.CrumbLink>
                )}
              </FormattedMessage>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <section className="c-page l-container">
        <Hero.Wrapper>
          <Hero.Title>
            {intl.formatMessage({
              id: 'containers.terms-and-conditions.headline',
            })}
          </Hero.Title>
        </Hero.Wrapper>

        <div className="c-markdown l-page-content">
          <FormattedMessage id="containers.terms-and-conditions.content">
            {(msg) => <Markdown options={{ forceBlock: true }}>{msg[0]}</Markdown>}
          </FormattedMessage>
        </div>
        <div className="l-center l-mt2">
          <Link to={listingRedirect} className="c-btn">
            {intl.formatMessage({
              id: 'containers.terms-and-conditions.back-to-homepage',
            })}
          </Link>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
